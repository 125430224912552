import groupBy from "lodash/groupBy"
import { enableUofAPIs } from "~/constant"

export const state = () => ({
    data: {},
    premiumData: {},
    bookmakerMarketData: {},
    fancyBookData: {},
    cleanData: [],
    clearPremiumData: [],
    clearBookmakerData: [],
    clearFancyBookData: [],
    newData: {},
    newPremiumData: {},
    newbookmakerMarketData: {},
    loading: false,
    error: {}
})

export const mutations = {
    // Normal Betfair data
    set_newData(state, data) {
        state.newData = groupBy(data, "marketId")
    },
    reset_newData(state) {
        state.newData = {}
    },
    set_data(state, data) {
        state.cleanData = data
        state.data = groupBy(data, "marketId")
        // console.log(state.data)
    },

    // premium data
    set_premiumData(state, data) {
        state.clearPremiumData = data
        state.premiumData = groupBy(data, "marketUniqueId")
    },
    set_newPremiumData(state, data) {
        state.newPremiumData = groupBy(data, "marketUniqueId")
    },
    reset_newPremiumData(state) {
        state.newPremiumData = {}
    },
    reset_premiumData(state) {
        state.premiumData = {}
    },

    // bookmaker data
    set_bookmakerData(state, data) {
        state.clearBookmakerData = data
        state.bookmakerMarketData = groupBy(data, "_id")
    },
    set_newbookmakerData(state, data) {
        state.newbookmakerMarketData = groupBy(data, "_id")
    },
    reset_newbookmakerData(state) {
        state.newbookmakerMarketData = {}
    },
    reset_bookmakerData(state) {
        state.bookmakerMarketData = {}
    },

    // fancy data
    set_fancyBookData(state, data) {
        state.clearFancyBookData = data
        state.fancyBookData = groupBy(data, "_id")
    },

    reset_fancyBookData(state) {
        state.fancyBookData = {}
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    async get({ commit }, matchIds, refresh) {
        if (refresh) {
            commit("set_data", {})
        }
        let accessToken
        if (this.$auth.loggedIn) {
            accessToken = this.$auth.getToken("customLocal")
        }
        try {
            // commit('set_loading', true)
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "book/getBooksForMarkets",
                data: {
                    _accessToken: accessToken,
                    matchIds,
                    isFancy: true
                }
            })
            commit("set_data", data.data)
            // commit('set_loading', false)
            this.commit("matchInside/filter_fancy", data.data)
        } catch (error) {
            commit("set_error", error)
            // commit('set_loading', false)
        }
    },
    async getAll({ commit }, matchIds, refresh) {
        if (refresh) {
            commit("set_data", {})
        }
        let accessToken
        if (this.$auth.loggedIn) {
            accessToken = this.$auth.getToken("customLocal")
        }
        try {
            // commit('set_loading', true)
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "book/getBooksForAllMarkets",
                data: {
                    _accessToken: accessToken,
                    matchIds
                }
            })
            commit("set_data", data.data)
            // commit('set_loading', false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getAllPremium({ commit }, { refresh, dataSet }) {
        if (!enableUofAPIs) return

        let outputURL = "UserNetExposure/getBooksForAllMarkets"
        if (dataSet) {
            outputURL =
                "UserNetExposure/getBooksForAllMarkets?eventId=" + dataSet
        }
        if (refresh) {
            commit("reset_premiumData")
        }
        try {
            // if (dataSet) {
            // console.log('payload', dataSet)
            const { data } = await this.$axios({
                method: "get",
                baseURL: process.env.UOF_SPORTSRADAR_SERVICE_URL,
                // url: 'UserNetExposure/getBooksForAllMarkets?eventId=' + outputURL
                url: outputURL
            })
            if (data && data.data && data.data.outputArray) {
                commit("set_premiumData", data.data.outputArray)
                this.commit("matchInside/filter_premium", data.data.outputArray)
            }
            // }
            // commit('set_loading', false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getAllBookmaker({ commit }, { refresh, dataSet }) {
        let outputURL = "bets/getBooksForAllMarkets"
        if (dataSet) {
            outputURL = "bets/getBooksForAllMarkets"
        }
        if (refresh) {
            commit("reset_bookmakerData")
        }
        try {
            if (dataSet) {
                const { data } = await this.$axios({
                    method: "post",
                    baseURL: process.env.PREMIUM_BOOKMAKER_URL,
                    url: outputURL,
                    data: {
                        eventId: [dataSet]
                    }
                })
                if (data && data.data) {
                    commit("set_bookmakerData", data.data)
                    this.commit(
                        "matchInside/filter_bookmaker_market",
                        data.data
                    )
                }
            }
            // commit('set_loading', false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getFancyBook({ commit }, betfairId, refresh) {
        if (refresh) {
            commit("set_data", {})
        }
        try {
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.PREMIUM_BOOKMAKER_URL,
                url: "book/getBooksForArtemisFancyMarkets",
                data: {
                    betfairId: betfairId.dataSet,
                    isFancy: true
                }
            })
            commit("set_fancyBookData", data.data)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
